import { useCallback, useMemo, useState } from "react";
import { REACT_APP_API_HOST as API_HOST , REACT_APP_API_HOST_SUPER_ADMIN as API_HOST_SUPER_ADMIN} from "../../envs";

interface IConfig {
  headers?: {
    Authorization?: string;
    "Content-Type"?: string;
  };
}

export const useNetwork = ({ updateState = true } = {}) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [statusCode, setStatusCode] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const config: IConfig = useMemo(() => ({}), []);
  const postConfig: IConfig = useMemo(() => ({}), []);

  postConfig.headers = {
    "Content-Type": "application/json",
    ...(config.headers ?? {}),
  };

  const get = useCallback(
    async (url: string, hostType?: string): Promise<any> => {
      setLoading(true);
      let apiUrl = API_HOST + url;
      if(hostType){
        switch (hostType){
          case 'super':
            apiUrl = API_HOST_SUPER_ADMIN + url;
            break;
          default:
            apiUrl = API_HOST + url;
        }
      }
      try {
        const response: any = await fetch(apiUrl, config);
        const apiPayload = await response.json();
        if (updateState) {
          setStatus(response?.ok);
          setIsLoaded(true);
          setData(apiPayload);
        }
        setStatusCode(response.status);
        return apiPayload;
      } catch (err: any) {
        if (updateState) {
          setError(err);
        }
        return null;
      } finally {
        if (updateState) {
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config]
  );

  const post = useCallback(
    async (url: string, requestJSON: any, hostType?: string) => {
      setLoading(true);
      let apiUrl = API_HOST + url;
      if(hostType){
        switch (hostType){
          case 'super':
            apiUrl = API_HOST_SUPER_ADMIN + url;
            break;
          default:
            apiUrl = API_HOST + url;
        }
      }
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          ...postConfig,
          body: JSON.stringify(requestJSON),
        });
        if (response.status === 500) {
          setError(response.type);
        }
        setStatusCode(response.status);
        setStatus(response?.ok);
        const apiData = await response.json();
        const apiResponse = apiData.data ?? apiData;
        setIsLoaded(true);
        setData(apiResponse);
        return apiResponse;
      } catch (err: any) {
        setError(err);
        return null;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postConfig]
  );

  const remove = useCallback(
    async (url: string, requestJSON?: any) => {
      try {
        const response: any = await fetch(API_HOST + url, {
          method: "DELETE",
          ...postConfig,
          body: JSON.stringify(requestJSON),
        });
        setStatus(response?.ok);
        const apiData = await response.json();

        setStatus(response.status);
        setIsLoaded(true);
        setData(apiData.data);
        return apiData.data;
      } catch (err: any) {
        setError(err);
        return null;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postConfig]
  );

  const patch = useCallback(
    async (url: string, requestJSON?: any) => {
      setLoading(true);
      try {
        const response: any = await fetch(API_HOST + url, {
          method: "PATCH",
          ...postConfig,
          body: JSON.stringify(requestJSON),
        });
        setStatus(response?.ok);
        const apiData = await response.json();
        setIsLoaded(true);
        const apiResponse = apiData.data ?? apiData;
        setData(apiResponse);
        return apiData.data;
      } catch (err: any) {
        setError(err);
        return null;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postConfig]
  );

  return {
    get,
    post,
    data,
    status,
    error,
    loading,
    setLoading,
    remove,
    patch,
    isLoaded,
    setIsLoaded,
    statusCode,
  };
};
